<script lang="ts" setup>
import { IconName } from '~/components/atoms/icon/Icon.vue'

export interface SocialLink {
  name: string
  icon: IconName
  url: string
}
const socialLinks: ComputedRef<SocialLink[]> = computed(() => [
  { name: 'linkedin', icon: 'linkedin', url: 'https://www.linkedin.com/company/careum' },
  { name: 'instagram', icon: 'instagram', url: 'https://www.instagram.com/careumgruppe' },
  { name: 'youtube', icon: 'youtube', url: 'https://www.youtube.com/channel/UCrgOHl5ElXoZAErqQ3WnPdA' },
  { name: 'blog', icon: 'blog', url: 'https://careum.ch/aktuell' },
  {
    name: 'spotify',
    icon: 'spotify',
    url: 'https://open.spotify.com/show/3jdRVjRYUTzr3pUlDHLrR9?si=7fc74d7755784236&nd=1&dlsi=44720b8a03e84b4b',
  },
])
</script>
<template>
  <div class="social">
    <h5 class="sotial-title label">{{ $t('footer.follow.title') }}</h5>
    <ul class="social-icons cul">
      <li v-for="(link, index) in socialLinks" :key="index">
        <NuxtLink class="social-icon" :to="link.url" target="_blank">
          <Icon :name="link.icon" />
        </NuxtLink>
      </li>
    </ul>
  </div>
</template>
<style lang="scss" scoped>
.social-title {
  margin-bottom: 24px;
}
.social-icons {
  display: flex;
  gap: 15px;
}
.social-icon {
  @include fluid(--size-icon, 28px, 32px);
  --color: var(--c-textlink);
  --hover-color: var(--c-blue-rollover);

  display: block;
  color: var(--color);

  @include transitions(color, transform);

  @include hover {
    color: var(--hover-color);
    transform: scale(0.95);
  }

  svg {
    width: var(--size-icon);
    height: var(--size-icon);
  }
}
</style>
